<template>
  <div>
    <h4>用户添加信息</h4>
    <form action="">
      <div style="display: flex">
        用户名：<input v-model="user.name" type="text" />
        <div style="width: 20px"></div>
        年龄: <input v-model="user.age" type="number" />
        <div style="width: 20px"></div>
        <input type="button" @click="addBtnAction" value="添加信息" />
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserAdd",
  data() {
    return {
      user: {},
    };
  },
  mounted() {},
  methods: {
    addBtnAction() {
      console.log(this.user);
      axios
        .post("http://rap2api.taobao.org/app/mock/310835/usre/add", this.user)
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 200) {
            this.$router.push("/user"); //切换路由
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>