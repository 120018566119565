<template>
  <div>22222222222222</div>
</template>

<script>
export default {
  name: "shoppingcar",

  data() {
    return {
      property: "value",
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>

div {
  background-color: blue;
}

</style>