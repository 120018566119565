<template>
  <div>
    <h4>用户编辑信息</h4>
    <form action="">
      <div style="display: flex">
        用户名：<input v-model="userId" type="text" />
        <div style="width: 20px"></div>
        年龄: <input v-model="userId" type="number" />
        <div style="width: 20px"></div>
        <input type="button" @click="EditBtnAction" value="编辑信息" />
      </div>
    </form>
  </div>
</template>

  <script>
export default {
  name: "UserEdit",
  data() {
    return {
      userId: null,
      user: {},
    };
  },
  created() {
    console.log("用户Name:" + this.$route.params);
    this.userId = this.$route.query.id;
  },
  mounted() {},
  methods: {
    EditBtnAction() {
      console.log("EditBtnAction");
      // this.$router.push("/user");
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>