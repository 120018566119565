import { createStore } from 'vuex'

export default createStore({
  state: {   //统一定义公共数据

    pathName: "这是store数据",
    currDbSource: {
      "name": "11",
      "age": "23",
      "address": "上海市",
    },
    currJobData: {},
    DbSource: [],
    homedata: [
      {
        "name": "11",
        "age": "23",
        "address": "上海市",
      },
      {
        "name": "11",
        "age": "23",
        "address": "上海市",
      },
      {
        "name": "11",
        "age": "23",
        "address": "上海市",
      },
      {
        "name": "11",
        "age": "23",
        "address": "上海市",
      },
      {
        "name": "11",
        "age": "23",
        "address": "上海市",
      }

    ]

  },
  mutations: {  //使用它来修改数据
  },
  actions: {  //发起异步请求
  },
  modules: {  //模块拆分
  }
})
