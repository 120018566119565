<template>
  <div class="productdetail" style="width: 100%; height: 100vh">
    <van-nav-bar :fixed="true" :safe-area-inset-top="true" title="产品详情" />
    <div style="margin-top: 46px;">
      {{ testParams }}
    </div>
  </div>
</template>

<script>
export default {
  name: "productdetail",
  data() {
    return {
      testParams: null,
    };
  },

  created() {
    this.testParams = this.$route.params.testParams;
    console.log("testParams:" + this.testParams);
  },

  mounted() {},

  components: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
/* 进入动画 */
.fold-enter-from {
  animation-name: fold-left-in;
  animation-duration: 5.3s;
}
.fold-enter-active {
  animation-name: fold-left-out;
  animation-duration: 5.3s;
}
.fold-right-enter-active {
  animation-name: fold-right-in;
  animation-duration: 5.3s;
}
.fold-right-leave-active {
  animation-name: fold-right-out;
  animation-duration: 5.3s;
}
</style>