import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../components/Home.vue'
import Student from '../components/Student.vue'
import account from '@/components/AccountMsg.vue'

import UserAdd from '@/components/UserAdd.vue'
import UserEdit from '@/components/UserEdit.vue'

import productlist from '../components/product/productlist.vue'
import shoppingcar from '../components/car/shoppingcar.vue'
import prodetail from '@/components/product/productdetail'

const routes = [
  {
    path: '/',
    redirect: "/account"
  },
  {
    path: '/account',
    name: 'account',
    component: account,
  },
  {
    path: '/user',  //页面路径
    name: 'user', //页面名称
    component: () => import('../components/User.vue'), //块语法
    children: [
      {
        path: 'add',
        name: 'useradd',
        component: UserAdd,
        meta: {
          title: "添加"
        }
      }, {
        path: 'edit',
        name: 'UserEdit',
        component: UserEdit
      }
    ]
  },
  {
    path: '/car',
    name: 'shoppingcar',
    component: shoppingcar
  },
  {
    path: '/student',
    name: 'student',
    component: Student
  },
  {
    path: '/product',
    name: 'productlist',
    component: productlist
  },
  {
    path: '/productdetail/:testParams',
    name: 'productdetail',
    component: prodetail
  }
]

const router = createRouter(
  {
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes
  }
)

export default router
