import { createApp } from 'vue'     //引入vue组件
import App from './App.vue'         //引入根组件
import router from './router'       //引入路由
import store from './store'
import vant from './ui/vant'    //vant组件
import 'vant/lib/index.css'
import axios from 'axios'

import './components/utils/flexible'
import './assets/css/index.css'

const app = createApp(App)
app.config.globalProperties.$axios = axios
app.use(store).use(router).use(vant).mount('#app')


