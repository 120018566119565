<template>
  <div id="productlist">
    <div>
      <div style="width: 100%; height: 150px">
        <scrollview></scrollview>
      </div>
      <div class="headtext">
        <span style="padding-left: 10px">{{ hometitle }}</span>
      </div>
      <div class="goods">
        <div class="menu-wrapper">
          <ul
            style="line-height: 50px"
            v-for="(item, index) in classArr"
            :key="index"
          >
            <li
              style="text-align: center"
              @click="itemClick(index)"
              :class="nowIndex == index ? 'active' : ''"
            >
              <div style="display: flex">
                <div :class="nowIndex == index ? 'active' : 'noactive'">|</div>
                <div style="padding-left: 5px">{{ item.name }}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="food-wrapper">
          <ul>
            <li v-for="(item, index) in dataArr" :key="index">
              <h1 class="title">{{ item }}</h1>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import scrollview from "./scrollview.vue";

export default {
  components: { scrollview },
  name: "productlist",
  data() {
    return {
      hometitle: "首页推荐",
      product: {},
      nowIndex: 0,
      classArr: [
        {
          id: 0,
          name: "热销",
        },
        {
          id: 1,
          name: "折扣",
        },
        {
          id: 2,
          name: "买一送一",
        },
        {
          id: 3,
          name: "多人餐",
        },
        {
          id: 4,
          name: "人气卷",
        },
      ],
      dataArr: ["产品1--", "产品2", "产品3", "产品4", "产品5"],
    };
  },
  created() {
    this.product.name = "111";
  },
  mounted() {},

  methods: {
    itemClick(index) {
      if (this.nowIndex != null && this.nowIndex == index) return;

      console.log("itemClick:" + index);
      this.nowIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.headtext {
  width: 100%;
  height: 50px;
  background-color: #dcdddd;
  overflow: hidden;
  line-height: 50px;
  font-size: 20px;
}
.goods {
  display: flex;
  position: absolute;
  top: 200px;
  bottom: 46px;
  width: 100%;
  overflow: hidden;

  .food-wrapper {
    flex: 1;
    overflow-y: auto;

    .ul {
      margin: 0px;
      padding: 0px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .menu-wrapper {
    flex: 0 0 80px;
    width: 180px;
    background-color: #dcdddd;
    color: #5e5e5e;
  }

  //
  .active {
    color: white;
    background-color: #919191;
  }

  .noactive {
    color: #dcdddd;
    background-color: #919191;
  }
}
</style>