
//仁科互动通用JS方法
export var RkhdUtils = {};

/***************************************************************************************************/

// const APIHeader = "http://103.192.253.93:8078/api"
const APIHeader = "https://business.sh600696.com/api";

//获取Token API
const CRMTOKEN_API = APIHeader + "/oauth2/token";

// 新建客户API
const ACCOUNT_API = APIHeader + "/external/xsy/rest/data/v2.0/xobjects/account";//"/rest/data/v2.0/xobjects/account";
// 批量上传图片API
const CRM_BATCH_IMG_API = APIHeader + "/external/xsy/rest/file/v2.0/image";//"/rest/file/v2.0/image";
// 批量上传文件API
const CRM_BATCH_FILE_API = APIHeader + "/external/xsy/rest/file/v2.0/file/batch";//"/rest/file/v2.0/file/batch";


const userName = "13020121809";
const password = "123456BmZzGuCT";
const refresh_token = "BmZzGuCT";
const client_id = "468bf836e21369a862f9175c70c146d3";
const client_secret = "654e7be5d6e794e528780c6622d6ea21";
const redirect_uri = "https://crm.xiaoshouyi.com";


/**
 * CRM查询接口
 * @param OrderNo
 * @returns {Promise<void>}
 * @constructor
 */

import { lapp } from "./NeoLappSdk.js";
import axios from 'axios'


RkhdUtils.CrmQueryV2Api = function (sql) {

    let res = lapp.connection.invoke({
        url: "/rest/data/v2/query",
        method: 'get',
        params: {
            "q": sql
        }
    }).then(
        res => {
            console.log(res)
            let data = res.data;
            console.log("查询接口--结果：", data)
            if (data.code == 200) {
                let result = data.result;
                let recordArr = result.records;
                if (recordArr.length > 0) {
                    let jsonObj = recordArr[0];
                    let responseObj = jsonObj;
                    return responseObj;
                }
            }
        },
        err => {
            console.log(err)
            return err;
        }
    )
    return res;

}


/**
 * 获取CRM-Token
 * @returns 
 */
RkhdUtils.GetTokenApi = function () {

    let res = axios.post(CRMTOKEN_API, {
        client_id: client_id,
        client_secret: client_secret,
        redirect_uri: redirect_uri,
        refresh_token: refresh_token,
        username: userName,
        password: password,
        grant_type: "password"
    }).then(function (response) {
        return response.data;
    }).catch(function (error) {
        return error;
    });
    return res;

}

/**
 * 创建客户API
 * @returns 
 */
RkhdUtils.CreateAccountAPI = function (data) {
    debugger
    let res = axios.post(ACCOUNT_API, data, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    }).then(function (response) {
        debugger
        return response.data;
    }).catch(function (error) {
        debugger
        return error;
    });
    return res;

}


/**
 * 图片上传接口
 * @param OrderNo
 * @returns {Promise<void>}
 * @constructor
 */
RkhdUtils.UpdateImageAPI = function (data) {

    let res = axios.post(CRM_BATCH_IMG_API, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }).then(function (response) {
        debugger
        return response.data;
    }).catch(function (error) {
        debugger
        return error;
    });
    return res;

}

/**
 * 图片文件接口
 * @param {*} data 
 * @param {*} token 
 * @returns 
 */
RkhdUtils.UpdateFileAPI = function (data) {

    let res = axios.post(CRM_BATCH_FILE_API, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }).then(function (response) {
        return response.data;
    }).catch(function (error) {
        return error;
    });
    return res;
}


// -------------------ACTION----------------------

//获取地址栏的某个参数
RkhdUtils.getParam = function (name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null)
        return unescape(r[2]);
    return null;
};


//获取地址栏的所有参数
RkhdUtils.getAllParam = function () {

    var url = decodeURI(window.location.search); //获取url中"?"符后的字符串
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
        }
    }
    return theRequest;
};


//时间戳转时间
RkhdUtils.getLocalTime = function (nS) {
    var date = new Date(parseInt(nS));
    var year = date.getFullYear();
    var mon = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    var hours = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
    var minu = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    var sec = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return year + '/' + mon + '/' + day + ' ' + hours + ':' + minu + ':' + sec;
};


/**
 * 从url获取id && token
 * @param variable  获取类型
 * @returns {string|boolean}
 */
RkhdUtils.getQueryVariable = function (variable) {

    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        if (variable == "access_token") {
            var pair = vars[i].split("token=");
            if (pair[0] == "access_") {
                return pair[1];
            }
        } else {
            const pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
    }
    return (false);
}
