<template>
  <div style="width: 100%; background-color: #f6f6f6">
    <h5 style="margin-top: 0; padding-top: 20px">基础信息</h5>
    <div style="width: 100%">
      <van-form style="width: 100%; padding-bottom: 50px">
        <van-cell-group inset>
          <van-field
            v-model="accountName"
            required
            label="客户名称"
            placeholder="客户名称"
            :rules="[{ required: true, message: '请填写客户名称' }]"
          ></van-field>
          <van-field
            type="abbreviation"
            v-model="acountShort"
            required
            label="客户简称"
            placeholder="客户简称,不超过7个字"
            :rules="[{ required: true, message: '请填写客户简称' }]"
          ></van-field>

          <van-field
            type="TotalSalesYear"
            v-model="TotalSalesYear"
            required
            name="合同年度销售总任务(万元)"
            label="合同年度销售总任务(万元)"
            placeholder="合同年度销售总任务（万元）"
            :rules="[{ required: true, message: '请填写合同年度销售总任务（万元）' }]"
          ></van-field>

          <van-field
            v-model="CustomerClass"
            is-link
            readonly
            required
            name="picker"
            label="贵酒匠经销客户分类"
            placeholder="贵酒匠经销客户分类"
            :rules="[{ required: true, message: '请选择贵酒匠经销客户分类' }]"
            @click="showPicker2 = true"
          />
          <van-popup v-model:show="showPicker2" position="bottom">
            <van-picker
              :columns="columns"
              @confirm="onConfirm"
              @cancel="showPicker2 = false"
            />
          </van-popup>

          <!-- <van-field
            v-model="CitySelection"
            required
            is-link
            readonly
            name="area"
            label="地区选择"
            placeholder="点击选择省市区"
            :rules="[{ required: true, message: '请选择省市区' }]"
            @click="showArea = true"
          ></van-field>
          <van-popup v-model:show="showArea" position="bottom">
            <van-area
              :area-list="cityList"
              @confirm="onConfirm"
              @cancel="showArea = false"
            />
          </van-popup> -->

          <van-field
            required
            v-model="addressValue"
            label="省市区,经营详细地址"
            placeholder="省市区，经营详细地址"
            :rules="[{ required: true, message: '省市区，请填写详细地址' }]"
          ></van-field>

          <van-field
            required
            v-model="HeaderNum"
            label="公司总人数"
            placeholder="公司总人数"
            :rules="[{ required: true, message: '请填写公司总人数' }]"
          ></van-field>
          <van-field
            v-model="storage"
            required
            name="仓储面积"
            label="仓储面积"
            placeholder="仓储面积"
            :rules="[{ required: true, message: '请填写仓储面积' }]"
          ></van-field>

          <van-field
            name="uploader"
            label="门头照片"
            required
            :rules="[{ required: true, message: '请上传添加门头照片' }]"
          >
            <template #input>
              <van-uploader
                tag:1
                v-model="MenTouValue"
                :after-read="afterRead"
                max-count="4"
                name="MenTou"
              />
            </template>
          </van-field>

          <van-field
            name="uploader"
            label="经营场所照片"
            required
            :rules="[{ required: true, message: '请上传经营场所照片' }]"
          >
            <template #input>
              <van-uploader
                v-model="JingYingValue"
                max-count="4"
                name="JingYing"
              />
            </template>
          </van-field>

          <van-field name="uploader" label="仓库照片">
            <template #input>
              <van-uploader v-model="CangKuValue" name="cangku" />
            </template>
          </van-field>

          <!-- <van-field name="uploader" label="车辆照片">
            <template #input>
              <van-uploader v-model="CheLiangValue" name="cheliang" />
            </template>
          </van-field> -->

          <van-field
            v-model="delegateMsg"
            required
            label="过往代理信息"
            placeholder="过往代理信息"
            :rules="[{ required: true, message: '请填写过往代理信息' }]"
          ></van-field>

          <van-field
            v-model="guildbg"
            is-link
            readonly
            required
            name="picker"
            label="行业背景"
            placeholder="行业背景"
            :rules="[{ required: true, message: '请选择行业背景' }]"
            @click="showPicker = true"
          />
          <van-popup v-model:show="showPicker" position="bottom">
            <van-picker
              :columns="guildbgArr"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>

          <van-field
            v-model="salesScale"
            required
            label="公司年销售规模"
            placeholder="公司年销售规模"
            :rules="[{ required: true, message: '请填写公司年销售规模' }]"
          ></van-field>

          <van-field
            v-model="AnnualSalesScale"
            required
            name="酒类年销售规模"
            label="酒类年销售规模"
            placeholder="酒类年销售规模"
            :rules="[{ required: true, message: '请填写酒类年销售规模' }]"
          ></van-field>

          <van-field name="uploader" label="租赁合同文件">
            <template #input>
              <van-uploader v-model="ZuLinValue" />
            </template>
          </van-field>
        </van-cell-group>

        <h5>工商信息</h5>
        <div style="width: 100%">
          <van-cell-group inset>
            <van-field
              name="uploader"
              label="营业执照照片"
              required
              :rules="[{ required: true, message: '请上传营业执照照片' }]"
            >
              <template #input>
                <van-uploader
                  v-model="ZhiZhaoValue"
                  max-count="5"
                  name="ZhiZhao"
                />
              </template>
            </van-field>

            <van-field
              v-model="licenseKey"
              label="食品经营许可证号"
              placeholder="食品经营许可证号"
            ></van-field>

            <van-field
              name="uploader"
              label="食品经营许可证照片"
              required
              :rules="[{ required: true, message: '请上传食品经营许可证照片' }]"
            >
              <template #input>
                <van-uploader
                  v-model="ShiPinValue"
                  max-count="3"
                  name="ShiPin"
                />
              </template>
            </van-field>

            <van-field
              required
              v-model="legalPerson"
              label="法人代表"
              placeholder="法人代表"
              :rules="[{ required: true, message: '请填写法人代表' }]"
            ></van-field>

            <van-field
              required
              type="tel"
              v-model.number="legalPhone"
              label="法人手机"
              placeholder="法人手机"
              :rules="[{ required: true, message: '请填写法人手机' }]"
            ></van-field>

            <van-field
              required
              v-model="legalCard"
              name="法人身份证"
              label="法人身份证"
              placeholder="法人身份证"
              :rules="[{ required: true, message: '请填写法人身份证' }]"
            ></van-field>

            <van-field
              name="uploader"
              label="法人身份证正反面照片"
              required
              :rules="[
                { required: true, message: '请上传法人身份证正反面照片' },
              ]"
            >
              <template #input>
                <van-uploader v-model="FaRenValue" max-count="4" name="FaRen" />
              </template>
            </van-field>
          </van-cell-group>
        </div>
        <div>
          <van-button
            style="position: fixed; bottom: 0; width: 100%"
            type="primary"
            size="large"
            native-type="submit"
            @click="sendAction"
            >提交信息</van-button
          >
          <div style="width: 100%; height: 10px"></div>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { RkhdUtils } from "../components/utils/RkhdUtils.js";

export default {
  name: "account",
  components: {},
  data() {
    return {
      showPicker: false,
      showPicker2: false,
      showArea: false,

      nowDepartment: null,

      entityType: 2916668883157272, //业务类型：贵酒酱经销店

      accountName: "", //客户名称
      acountShort: "", //客户简称
      TotalSalesYear: null, //合同年度销售总任务
      CitySelection: null, //省市区选择
      addressValue: "", //详细地址
      HeaderNum: null, //公司总人数
      storage: "", //仓储面积
      delegateMsg: "", //过往代理信息

      CustomerClass: null, //贵酒匠经销客户分类text
      CustomerIndex: null, //贵酒匠经销客户分类value

      guildbg: null, //行业背景text
      guildIndex: null, //行业背景value

      salesScale: null, //公司年销售规模
      AnnualSalesScale: null, //酒类年销售规模
      licenseKey: null, //食品经营许可证号
      legalPerson: null, //法人代表
      legalPhone: null, //法人手机号
      legalCard: null,

      cityList: areaList,
      result: null,
      columns: [
        { text: "大客户", value: 1 },
        { text: "体验店", value: 2 },
        { text: "平台商", value: 3 },
        { text: "会员店", value: 4 },
        { text: "幸福小店", value: 5 },
        { text: "渠道商", value: 6 },
      ],
      guildbgArr: [
        { text: "跨界商", value: 1 },
        { text: "行业商", value: 2 },
      ],

      MenTouValue: [], //门头照片 文件
      SaveMentouArr: [],

      JingYingValue: [], //经营场所照片   文件
      SaveJingYingArr: [],

      CangKuValue: [], //仓库照片 文件
      CangKuArr: [],

      CheLiangValue: [], //车辆照片

      ZuLinValue: [], //租赁合同文件  文件
      zulinArr: [],

      ZhiZhaoValue: [], //营业执照照片  图片 5张
      ZhiZhaoArr: [],

      ShiPinValue: [], //食品经营许可证照片 图片 3张
      shipinArr: [],

      FaRenValue: [], //法人身份证正反面照片 图片  4张
      farenArr: [],
    };
  },
  mounted() {
    /*
    RkhdUtils.GetTokenApi()
      .then((res) => {
        this.NowToken = res.access_token;
        console.log("NowToken:", this.NowToken);
      })
      .catch((err) => {
        console.log(err);
      });
      */
  },

  methods: {
    onConfirm(select, index) {
      console.log(select);
      console.log(index);

      if (this.showPicker2) {
        this.showPicker2 = false;
        this.CustomerClass = select.text;
        this.CustomerIndex = select.value;
      } else {
        this.showPicker = false;
        this.guildbg = select.text;
        this.guildIndex = select.value;
      }
    },

    /**
     * 图片上传触发
     * @param {*} file
     * @param {*} detail
     */
    afterRead(file, detail) {
      console.log(file.file);
      console.log(detail);
    },

    async getmenttouAction() {
      //门头照片
      for (let i in this.MenTouValue) {
        let item = this.MenTouValue[i];
        const fromdata = new FormData();
        fromdata.append("files", item.file);
        fromdata.append("isImage", false);
        fromdata.append("needFileId", true);

        if (this.SaveMentouArr.length > 0) {
          this.SaveMentouArr.length = 0;
        }
        await RkhdUtils.UpdateFileAPI(fromdata)
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              let fileobj = {};
              if (res.data.code == 200) {
                let fileId = res.data.result[0].fileId;
                fileobj.id = fileId;
                this.SaveMentouArr.push(fileobj);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async getJingYinAction() {
      //经营场所照片
      for (let i in this.JingYingValue) {
        let item = this.JingYingValue[i];
        const fromdata = new FormData();
        fromdata.append("files", item.file);
        fromdata.append("isImage", false);
        fromdata.append("needFileId", true);
        if (this.SaveJingYingArr.length > 0) {
          this.SaveJingYingArr.length = 0;
        }
        await RkhdUtils.UpdateFileAPI(fromdata)
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              let fileobj = {};
              if (res.data.code == 200) {
                let fileId = res.data.result[0].fileId;
                fileobj.id = fileId;
                this.SaveJingYingArr.push(fileobj);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async getCantKuAction() {
      //仓库照片
      for (let i in this.CangKuValue) {
        let item = this.CangKuValue[i];
        const fromdata = new FormData();
        fromdata.append("files", item.file);
        fromdata.append("isImage", false);
        fromdata.append("needFileId", true);
        if (this.CangKuArr.length > 0) {
          this.CangKuArr.length = 0;
        }
        await RkhdUtils.UpdateFileAPI(fromdata)
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              let fileobj = {};
              if (res.data.code == 200) {
                let fileId = res.data.result[0].fileId;
                fileobj.id = fileId;
                this.CangKuArr.push(fileobj);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async getZulinAction() {
      //租赁合同照片
      for (let i in this.ZuLinValue) {
        let item = this.ZuLinValue[i];
        const fromdata = new FormData();
        fromdata.append("files", item.file);
        fromdata.append("isImage", false);
        fromdata.append("needFileId", true);

        if (this.zulinArr.length > 0) {
          this.zulinArr.length = 0;
        }
        await RkhdUtils.UpdateFileAPI(fromdata)
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              let fileobj = {};
              if (res.data.code == 200) {
                let fileId = res.data.result[0].fileId;
                fileobj.id = fileId;
                this.zulinArr.push(fileobj);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async getZhiZhaoAction() {
      //营业执照照片
      const fromdata = new FormData();
      for (let i in this.ZhiZhaoValue) {
        let item = this.ZhiZhaoValue[i];
        fromdata.append("files", item.file);
      }
      console.log("fromdata:", fromdata);

      if (this.ZhiZhaoArr.length > 0) {
        this.ZhiZhaoArr.length = 0;
      }
      await RkhdUtils.UpdateImageAPI(fromdata)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            const data = res.data;
            if (data.code == 200) {
              for (let i in data.result) {
                let fileobj = {};
                let obj = data.result[i];
                fileobj.id = obj.data.fileId;
                this.ZhiZhaoArr.push(fileobj);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getShiPinAction() {
      //食品许可证照片
      const fromdata2 = new FormData();
      for (let i in this.ShiPinValue) {
        let item = this.ShiPinValue[i];
        fromdata2.append("files", item.file);
      }
      console.log("fromdata2:", fromdata2);

      if (this.shipinArr.length > 0) {
        this.shipinArr.length = 0;
      }
      await RkhdUtils.UpdateImageAPI(fromdata2)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            const data = res.data;
            if (data.code == 200) {
              for (let i in data.result) {
                let fileobj = {};
                let obj = data.result[i];
                fileobj.id = obj.data.fileId;
                this.shipinArr.push(fileobj);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getFaRenAction() {
      //法人身份证照片
      const fromdata3 = new FormData();
      for (let i in this.FaRenValue) {
        let item = this.FaRenValue[i];
        fromdata3.append("files", item.file);
      }
      console.log("fromdata3:", fromdata3);

      if (this.farenArr.length > 0) {
        this.farenArr.length = 0;
      }
      await RkhdUtils.UpdateImageAPI(fromdata3)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            const data = res.data;
            if (data.code == 200) {
              for (let i in data.result) {
                let fileobj = {};
                let obj = data.result[i];
                fileobj.id = obj.data.fileId;
                this.farenArr.push(fileobj);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async sendAction() {
      this.nowDepartment = RkhdUtils.getParam("department");
      console.log("所属事业部:", this.nowDepartment);

      if (this.nowDepartment == null) {
        this.$toast("所属事业部为空，H5链接有问题，请核实");
        return;
      }

      if (this.accountName == "") {
        this.$toast("请输入客户名称");
        return;
      }
      if (this.acountShort == "") {
        this.$toast("请输入客户简称");
        return;
      }
      if (this.acountShort.length > 7) {
        this.$toast("客户简称不能超过7个字");
        return;
      }
      if (this.acountShort == "") {
        this.$toast("请输入客户简称");
        return;
      }
      if (this.TotalSalesYear == 0.0) {
        this.$toast("请输入合同年度销售总任务（万元）");
        return;
      }
      if (this.CustomerIndex == null) {
        this.$toast("请选择贵酒匠经销客户分类");
        return;
      }
      if (this.addressValue == "") {
        this.$toast("请输入经营详细地址");
        return;
      }
      if (this.HeaderNum == 0) {
        this.$toast("请输入公司总人数");
        return;
      }
      if (this.storage == "") {
        this.$toast("请输入仓储面积");
        return;
      }

      if (this.MenTouValue.length == 0) {
        this.$toast("请上传门头照片");
        return;
      }

      if (this.JingYingValue.length == 0) {
        this.$toast("请上传经营场所照片");
        return;
      }

      if (this.delegateMsg == "") {
        this.$toast("请输入过往代理信息");
        return;
      }
      if (this.guildIndex == null) {
        this.$toast("请选择行业背景");
        return;
      }
      if (this.salesScale == null) {
        this.$toast("请填写公司年销售规模");
        return;
      }
      if (this.AnnualSalesScale == null) {
        this.$toast("请填写酒类年销售规模");
        return;
      }

      if (this.ZhiZhaoValue.length == 0) {
        this.$toast("请上传营业执照照片");
        return;
      }

      if (this.licenseKey == null) {
        this.$toast("请填写食品经营许可证号");
        return;
      }

      if (this.ShiPinValue.length == 0) {
        this.$toast("请上传食品经营许可证照片");
        return;
      }

      if (this.legalPerson == "") {
        this.$toast("请填写法人代表");
        return;
      }
      if (this.legalPhone == "") {
        this.$toast("请填写法人手机号");
        return;
      }
      if (this.legalCard == "") {
        this.$toast("请上传法人身份证");
        return;
      }

      if (this.FaRenValue.length == 0) {
        this.$toast("请上传法人身份证照片");
        return;
      }

      this.$toast.loading({
        message: "提交中...",
        duration: 0,
        // forbidClick: true,
      });

      await this.getmenttouAction();
      await this.getJingYinAction();
      await this.getCantKuAction();
      await this.getZulinAction();
      await this.getZhiZhaoAction();
      await this.getShiPinAction();
      await this.getFaRenAction();

      //创建客户API
      const requestObj = {
        dimDepart: this.nowDepartment,
        entityType: this.entityType,
        accountName: this.accountName,
        customItem257__c: this.acountShort,
        customItem280__c: this.TotalSalesYear,
        customItem174__c: this.CustomerIndex,
        address: this.addressValue,
        employeeNumber: this.HeaderNum,
        warehouse__c: this.storage,
        Proxy__c: this.delegateMsg,
        customItem253__c: this.guildIndex,
        customItem187__c: this.salesScale,
        customItem188__c: this.AnnualSalesScale,
        customItem198__c: this.licenseKey,
        customItem200__c: this.legalPerson,
        customItem202__c: this.legalPhone,
        customItem203__c: this.legalCard,
        customItem245__c: 3, //事业部：贵科
        customItem268__c: this.nowDepartment,
        Empower_phone__c__c: this.legalPhone,
        Empower_name__c: this.legalPerson,
        Empower_card__c: this.legalCard,
        Empower_photo__c: this.farenArr,
        customItem273__c: this.SaveMentouArr, // 门头照片
        customItem271__c: this.SaveJingYingArr, //经营场所照片
        customItem274__c: this.CangKuArr, //仓库照片
        customItem269__c: this.zulinArr, //租赁照片
        customItem197__c: this.ZhiZhaoArr, //营业执照照片
        customItem199__c: this.shipinArr, //食品经营许可证照片
        customItem204__c: this.farenArr, //法人身份证正反面照片
      };
      console.log("requestObj:", requestObj);
      RkhdUtils.CreateAccountAPI({ data: requestObj })
        .then((res) => {
          this.$toast.clear();
          console.log("res:", res);
          if (res.code == 200) {
            this.$toast.success("资料提交成功！");
          } else {
            this.$toast.fail(res.message);
          }
        })
        .catch((error) => {
          console.log("error:", error);
          this.$toast.clear();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>