<template>
  <div class="student" style="width: 100%; height: 100vh">
    <van-nav-bar :fixed="true" :safe-area-inset-top="true" title="市场" />
    <div style="margin-top: 2.875rem; padding-bottom: 4rem">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell
            center
            v-for="item in datalist"
            :key="item"
            :title="item.id"
            :value="item.name"
            :label="item.phone"
            is-link
            @click="cellclick(item)"
          >
            <div style="display: flex">
              <img
                style="width: 100px; height: 100px; margin-right: 10px"
                src="../assets/lunbo1.jpg"
                alt=""
              />
              <div>
                {{ item.name }}
                <div class="blue">{{ item.phone }}</div>
              </div>
            </div>

            <!-- <van-space>
              <van-button type="primary">按钮</van-button>
              <van-button type="primary">按钮</van-button>
              <van-button type="primary">按钮</van-button>
              <van-button type="primary">按钮</van-button>
            </van-space> -->
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
  <!-- <Footer></Footer> -->
</template>

<script>
// import Footer from "./Footer.vue";

const NowUrl = "http://10.89.103.146:8091";

export default {
  data() {
    return {
      list: [],
      datalist: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },

  created() {
    console.log("created");
    this.$axios
      .get(NowUrl + "/ookpro/queryAll")
      .then((response) => {
        const datalist = response.data;
        if (datalist.length > 0) {
          for (const i in datalist) {
            this.datalist.push(datalist[i]);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  },

  methods: {
    onLoad() {
      this.$axios
        .get(NowUrl + "/ookpro/queryAll")
        .then((res) => {
          const datalist = res.data;
          if (datalist.length > 0) {
            this.finished = true;
            for (const i in res.data) {
              const item = res.data[i];
              item.id = res.data.length + item.id;
              this.datalist.push(item);
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.finished = true;
        });
    },

    onRefresh() {
      this.datalist = [];
      this.$axios
        .get(NowUrl + "/ookpro/queryAll")
        .then((res) => {
          const datalist = res.data;
          if (datalist.length > 0) {
            for (const i in res.data) {
              this.datalist.push(res.data[i]);
            }

            this.finished = false;
            this.loading = true;
            setTimeout(() => {
              this.refreshing = false;
              this.onLoad();
            }, 2000);
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.refreshing = true;
        });
    },

    cellclick(item) {
      console.log(item);
      this.$router.push({
        name: "productdetail",
        params: { testParams: item.id },
      });
      // this.$router.push("/home");
    },
  },

  components: {
    // Footer,
  },
};
</script>

<style scoped>
:root {
  --van-white: #fff;
  --van-blue: #1989fa;
}

.label-class {
  font-size: 50px;
}

.van-list {
  margin-top: 10px;
}

.blue {
  color: gray;
  font-size: medium;
}

.van-cell {
  /* background-color: var(--van-white); */
  height: 175px;
  /* margin-top: 10px; */
  /* font-size: 1.25rem; */
  /* border-radius: 30rpx; */
  /* --van-cell-line-height: 50px; */
}

/* 去除单元格cell下边框 */
/* .van-cell:after {
  border-bottom: none !important;
  border-bottom-style: none !important;
} */
</style>