import {
  Overlay, Button, Loading, Uploader,
  Toast, Swipe, SwipeItem, Tabbar,
  TabbarItem, NavBar, List, Notify, PullRefresh,
  Space, Cell, Form, Field, CellGroup, Popup, Area, Picker
} from 'vant'

const vant = {
  install: function (Vue) {
    Vue.use(Overlay)
    Vue.use(Button)
    Vue.use(Loading)
    Vue.use(Uploader)
    Vue.use(Toast)
    Vue.use(Swipe)
    Vue.use(SwipeItem)
    Vue.use(Tabbar)
    Vue.use(TabbarItem)
    Vue.use(NavBar)
    Vue.use(List)
    Vue.use(Notify)
    Vue.use(PullRefresh)
    Vue.use(Space)
    Vue.use(Cell)
    Vue.use(CellGroup)
    Vue.use(Form);
    Vue.use(Field);
    Vue.use(Popup);
    Vue.use(Area);
    Vue.use(Picker);


  }
}

export default vant