<template>
  <div>
    <!-- <router-view></router-view> -->
    <!-- <tabbar></tabbar> -->
    <account></account>
  </div>
</template>

<script>
// import Tabbar from "@/components/tabbar/tabbar.vue";
import account from "@/components/AccountMsg.vue";

export default {
  data() {
    return {
      property: "value",
    };
  },

  components: {
    // Tabbar,
    account,
  },
};
</script>

<style lang="scss">
</style>
