<template>
  <div style="width: 100%; height: 100%">
    <van-swipe class="my-swipe" :autoplay="2000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in imageList" :key="index">
        <img :src="require('../../assets/' + item.img)" alt="" />
        <span class="imgWord">
          <span class="imgWordWidth">{{ item.text }}</span>
        </span>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: "scrollview",

  data() {
    return {
      imageList: [
        {
          id: 0,
          img: "lunbo1.jpg",
          text: "奥迪",
        },
        {
          id: 1,
          img: "lunbo2.jpg",
          text: "法拉利",
        },
        {
          id: 2,
          img: "lunbo3.jpg",
          text: "宝马",
        },
        {
          id: 3,
          img: "lunbo4.jpg",
          text: "保时捷",
        },
        {
          id: 4,
          img: "lunbo5.png",
          text: "奔驰",
        },
      ],
      imageListIndex: 0,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  //   line-height: 150px;
  text-align: center;
  //   background-color: #39a9ed;
}

img {
  width: 100%;
  height: 150px;
  object-fit: cover;

  //   fill	不保持纵横比缩放图片，使图片完全适应
  // contain	保持纵横比缩放图片，使图片的长边能完全显示出来
  // cover	保持纵横比缩放图片，只保证图片的短边能完全显示出来
  // none	保持图片宽高不变
  // scale-down	当图片实际宽高小于所设置的图片宽高时，显示效果与none一致；否则，显示效果与contain一致
}

/* 图片的文字 */
.imgWord {
  position: absolute;
  bottom: 1.6vw;
  left: 3.5vw;
  width: 91vw;
  height: 10vw;
  line-height: 10vw;
  text-align: left;
  padding-left: 2vw;
  border-radius: 3vw 3vw 3vw 3vw;
  background: rgba(22, 22, 22, 0.5);
  color: #feffff;
  font-size: 20px;
}
/* 文字的宽度 */
.imgWordWidth {
  position: absolute;
  bottom: 0vw;
  left: 3vw;
  width: 75vw;
  overflow: hidden;
  text-overflow: clip;
  white-space: pre;
  padding: 0 1vw;
}
</style>